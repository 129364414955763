import React, { useEffect, useState } from "react";
import heroImage from "./hero.png";

const Home = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoaded(true);
    }, 1000);

    return () => clearTimeout(delay);
  }, []);

  return (
    <div className={`hero ${loaded ? "fade-in" : ""}`}>
      <div className="hero">
        <div className="heroText">
          <h1>DEEP IS GOOD</h1>
          <br />
          <p>Dont believe the misinformation.</p>
          <p>The Deep State are the good guys.</p>
          <br />
          <p>
            <i>Join us and help change the narrative.</i>
          </p>
          <br />
          <div className="buttonWrapper">
            <a
              href="https://t.me/deepstatefeds"
              target="_blank"
              rel="noreferrer"
            >
              <div className="button">JOIN</div>
            </a>
          </div>
        </div>
        <img className="heroImage" src={heroImage} alt="scanner" />
      </div>
    </div>
  );
};

export default Home;
