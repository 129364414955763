import React, { useEffect, useState } from "react";
import Hero from "./hero";
import Token from "./token";
import Bot from "./bot";
import Projects from "./projects";
import "./home.css";

const Home = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoaded(true);
    }, 500);

    return () => clearTimeout(delay);
  }, []);

  return (
    <div className={`pages ${loaded ? "fade-in" : ""}`}>
      <Hero />
      <Token />
      <Bot />
      <Projects />
    </div>
  );
};

export default Home;
