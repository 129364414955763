import React, { useEffect, useState } from "react";
import "./projects.css"; // Make sure to create and import a CSS file for styles

const Projects = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoaded(true);
    }, 1000);

    return () => clearTimeout(delay);
  }, []);

  const projects = [
    {
      name: "DISCLOSURE DAO",
      emoji: "👽",
      link: "https://t.me/disclosureDAO",
      rewards: "Presale Tokens",
      nextPayout: "Long Term",
    },
    {
      name: "BONER",
      emoji: "🍆",
      link: "https://t.me/BonerOnArbitrum",
      rewards: "$BONER",
      nextPayout: "Bi-weekly",
    },
    {
      name: "ZELEBOBA",
      emoji: "🍪",
      link: "https://t.me/zeleboba_lfg",
      rewards: "$ZELE",
      nextPayout: "Weekly",
    },
  ];

  return (
    <div className={`hero ${loaded ? "fade-in" : ""}`}>
      <div className="token">
        <div className="heroText">
          <h1>FEATURED PROJECTS</h1>
          <h5>
            Click the cards to navigate to their chats and start earning raiding
            rewards today!
          </h5>
          <br />
          <div className="cards-container">
            {projects.map((project, index) => (
              <a
                key={index}
                href={project.link}
                target="_blank"
                rel="noopener noreferrer"
                className="card"
              >
                <div className="emoji">{project.emoji}</div>
                <div className="projectName">{project.name}</div>
                <br />
                <div>Rewards: {project.rewards}</div>
                <br />

                <div>Interval: {project.nextPayout}</div>
              </a>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
