import "./footer.css";
import telegram from "../../assets/logos/telegram.png";
import x from "../../assets/logos/x.png";
import etherscan from "../../assets/logos/etherscan.png";

const Footer = () => {
  return (
    <div>
      <div className="footer">
        <div className="footerLinks">
          <a
            className="footerLink"
            href="https://docs.deepstatefeds.org"
            target="_blank"
            rel="noreferrer"
          >
            DOCS
          </a>
          <a
            className="footerLink"
            href="https://t.me/agentandr00"
            target="_blank"
            rel="noreferrer"
          >
            CONTACT
          </a>
        </div>
        <div className="footerMidSpacer"> </div>
        <div className="logos">
          <a
            href="https://t.me/deepstatefeds"
            target="_blank"
            rel="noreferrer"
            className="logo"
          >
            <img src={telegram} alt="Telegram" />
          </a>
          <a
            href="https://x.com/deepstatefeds"
            target="_blank"
            rel="noreferrer"
            className="logo"
          >
            <img src={x} alt="x" />
          </a>
          <a
            href="https://etherscan.io/token/0xA1b91A8119B782c1d938389F84dbec70d25C8b25"
            target="_blank"
            rel="noreferrer"
            className="logo"
          >
            <img src={etherscan} alt="Etherscan" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
