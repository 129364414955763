import { createContext, useContext, useState, useEffect } from "react";
import { recoverAddress, getBytes, hashMessage } from "ethers";
import axios from "axios";
const base_url = process.env.REACT_APP_BASE_URL;
const secret_token = process.env.REACT_APP_SECRET_TOKEN;
const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [connectedAddress, setConnectedAddress] = useState("");
  const [signedInAddress, setSignedInAddress] = useState("");
  const [userEntry, setUserEntry] = useState("");
  const [showRegistration, setShowRegistration] = useState(false);
  const [isSignInComplete, setIsSignInComplete] = useState(false);

  const disconnect = () => {
    setConnectedAddress("");
    setIsSignedIn(false);
  };

  const connectAndSignIn = async () => {
    try {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const address = accounts[0];
        setConnectedAddress(address);

        const message =
          "Sign this message in order to access your DEEP STATE account.";
        const signedMessage = await window.ethereum.request({
          method: "personal_sign",
          params: [message, address],
        });

        const recoveredAddr = recoverAddress(
          getBytes(hashMessage(message)),
          signedMessage
        );
        setSignedInAddress(recoveredAddr);
        setIsSignedIn(true);
        fetchUserbyAddress(signedInAddress, setUserEntry);
        setIsSignInComplete(true);
      } else {
        alert(
          "MetaMask is not installed. Please install it to connect your wallet."
        );
      }
    } catch {}
  };

  const fetchUserbyAddress = async (signedInAddress, setUserEntry) => {
    try {
      const response = await axios.get(
        `${base_url}/address/${signedInAddress.toLowerCase()}`,
        {
          headers: {
            Authorization: `Token ${secret_token}`,
          },
        }
      );
      setUserEntry(response.data);
    } catch (error) {
      setUserEntry(null);
    }
  };

  useEffect(() => {
    if (isSignInComplete && userEntry === "") {
      fetchUserbyAddress(signedInAddress, setUserEntry);
    }
    setShowRegistration(userEntry === null);
    // eslint-disable-next-line
  }, [isSignInComplete, userEntry]);

  const handleShowRegistration = () => {
    setShowRegistration((prevShowRegistration) => !prevShowRegistration);
  };

  return (
    <WalletContext.Provider
      value={{
        isSignedIn,
        connectedAddress,
        signedInAddress,
        userEntry,
        showRegistration,
        handleShowRegistration,
        connectAndSignIn,
        disconnect,
        setUserEntry,
        fetchUserbyAddress,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => {
  return useContext(WalletContext);
};
