import React, { useState, useEffect } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import {
  Chart,
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
} from "chart.js";
import "chartjs-adapter-date-fns";

Chart.register(
  LinearScale,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip
);

Chart.defaults.plugins.datalabels = { display: false };

const base_url = process.env.REACT_APP_BASE_URL;
const secret_token = process.env.REACT_APP_SECRET_TOKEN;

const Bot = () => {
  const [loaded, setLoaded] = useState(false);
  const [stats, setStats] = useState({});
  const [linechartData, setLineChartData] = useState({});
  const [linechartOptions, setLineChartOptions] = useState({});
  const [latestNumbers, setLatestNumbers] = useState({ projects: 0, users: 0 });

  useEffect(() => {
    const delay = setTimeout(() => {
      setLoaded(true);
    }, 1000);
    return () => clearTimeout(delay);
  }, []);

  useEffect(() => {
    const getStats = async () => {
      try {
        const response = await axios.get(`${base_url}/botstats`, {
          headers: {
            Authorization: `Token ${secret_token}`,
          },
        });
        setStats(response.data);
        updateChartData(response.data);
        updateLatestNumbers(response.data);
      } catch (error) {
        console.error("Error fetching stats:", error);
      }
    };

    if (Object.keys(stats).length === 0 && stats.constructor === Object) {
      getStats();
    }
    // eslint-disable-next-line
  }, []);

  const updateChartData = (statsData) => {
    const labels = Object.keys(statsData);
    const projectsData = labels.map((label) => statsData[label][0]);
    const usersData = labels.map((label) => statsData[label][1]);

    const data = {
      labels,
      datasets: [
        {
          label: "Projects",
          data: projectsData,
          borderColor: "rgb(53, 162, 235)",
          backgroundColor: "rgba(53, 162, 235, 0.5)",
          yAxisID: "linear",
          type: "line",
        },
        {
          label: "Users",
          data: usersData,
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          yAxisID: "users",
          type: "line",
        },
      ],
    };
    setLineChartData(data);
  };

  const updateLatestNumbers = (statsData) => {
    const labels = Object.keys(statsData);
    if (labels.length > 0) {
      const lastLabel = labels[labels.length - 1];
      setLatestNumbers({
        projects: statsData[lastLabel][0],
        users: statsData[lastLabel][1],
      });
    }
  };

  useEffect(() => {
    configureChartOptions();
    const handleResize = () => {
      configureChartOptions();
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const configureChartOptions = () => {
    const screenWidth = window.innerWidth;
    const isMobile = screenWidth < 770;
    const isDesktop = screenWidth >= 1100;

    let xFontSize = 8;
    let linearFontSize = 12;
    let usersFontSize = 12;
    let pointRadius = 1;
    let tooltipTitleFontSize = 10;
    let tooltipBodyFontSize = 8;

    if (isMobile) {
      xFontSize = 5;
      linearFontSize = 5;
      usersFontSize = 5;
      pointRadius = 1;
      tooltipTitleFontSize = 6;
      tooltipBodyFontSize = 8;
    } else if (isDesktop) {
      xFontSize = 10;
      linearFontSize = 20;
      usersFontSize = 20;
      pointRadius = 1;
      tooltipTitleFontSize = 20;
      tooltipBodyFontSize = 15;
    }

    const lineoptions = {
      interaction: {
        mode: "index",
        intersect: false,
      },
      scales: {
        x: {
          type: "category",
          display: true,
          ticks: {
            font: {
              size: xFontSize,
            },
          },
        },
        linear: {
          type: "linear",
          display: true,
          position: "left",
          title: {
            display: true,
            text: "Projects",
            font: {
              size: linearFontSize,
            },
          },
          ticks: {
            font: {
              size: linearFontSize,
            },
          },
        },
        users: {
          type: "linear",
          display: true,
          position: "right",
          grid: {
            drawOnChartArea: false,
          },
          title: {
            display: true,
            text: "Users",
            font: {
              size: usersFontSize,
            },
          },
          ticks: {
            font: {
              size: usersFontSize,
            },
          },
        },
      },
      elements: {
        point: {
          radius: pointRadius,
        },
        line: {
          tension: 0,
        },
      },
      plugins: {
        title: {
          display: false,
        },
        tooltip: {
          titleFont: {
            size: tooltipTitleFontSize,
          },
          bodyFont: {
            size: tooltipBodyFontSize,
          },
          mode: "index",
          position: "nearest",
          intersect: false,
        },
      },
    };
    setLineChartOptions(lineoptions);
  };

  return (
    <div className={`bot ${loaded ? "fade-in" : ""}`}>
      <div>
        <div className="token">
          <h1>FEDS AGENT BOT</h1>
        </div>
        <div className="flex spacebetween">
          <div className="linegraph margin infoBox">
            <h3>REGISTERED USERS AND PROJECTS</h3>
            <p>Projects:{latestNumbers.projects}</p>
            <p>Users:{latestNumbers.users}</p>
            {Object.keys(stats).length > 0 && (
              <Line data={linechartData} options={linechartOptions} />
            )}
          </div>
          <div className="infoBox margin botbox">
            <h3>HOW IT WORKS</h3>
            🗒 Track and reward your community's activity on Telegram and X<br />
            <br />
            🚨 Raid important X posts until targets are met
            <br />
            <br />
            💰 Pay rewards in any token on chain for every point earned <br />
            <br />
            <a
              href="https://t.me/FedsAgentBot"
              target="_blank"
              rel="noreferrer"
            >
              <div className="button botbutton">ADD BOT TO CHAT</div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Bot;
