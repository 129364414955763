import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import axios from "axios";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ArcElement, ChartDataLabels);

const base_url = process.env.REACT_APP_BASE_URL;
const secret_token = process.env.REACT_APP_SECRET_TOKEN;

const Token = () => {
  const [balances, setBalances] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [ethPrice, setEthPrice] = useState(1);

  const addressDict = {
    "BASE HOLDERS": "0x3ee18b2214aff97000d974cf647e7c347e8fa585",
    "BASE LP": "0xbfc4b659c2b5e22b650ecfaf0fe823465f74e229",
    "BASE TREASURY": "0x1193668bfdc833146cd947c9e78c33e77c7683d3",
    "ETH LP": "0xd46557dcd77C874ef7e516Af6f26e6e79C831a8e",
  };

  useEffect(() => {
    const getETHPrice = async () => {
      try {
        const response = await axios.get(
          "https://api.etherscan.io/api?module=stats&action=ethprice&apikey=3JGAJUKSIMFXWNJWDB4X9QY5CWYP8P262A"
        );
        setEthPrice(response.data.result.ethusd);
      } catch (error) {}
    };

    const getBalances = async () => {
      const balancesTemp = {};

      for (const [name, address] of Object.entries(addressDict)) {
        try {
          const response = await axios.get(
            `${base_url}/holder/${address.toLowerCase()}`,
            {
              headers: {
                Authorization: `Token ${secret_token}`,
              },
            }
          );
          balancesTemp[name] = Number(response.data) / 10 ** 18;
        } catch (error) {
          console.error(`Error fetching data for ${name}:`, error);
        }
      }
      setBalances(balancesTemp);
    };

    const updateChartOptions = () => {
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
      const baseFontSize = isMobile ? 3 : window.innerWidth / 120; // Smaller base font size for mobile
      const newOptions = {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
          padding: isMobile ? 10 : 35,
        },
        plugins: {
          datalabels: {
            color: "#fff",
            backgroundColor: "#333",
            borderRadius: isMobile ? 1 : 5,
            borderStyle: "solid",
            borderWidth: isMobile ? 0.5 : 1,
            borderColor: "#fff",
            anchor: "end",
            padding: isMobile ? 1 : 5,
            font: {
              size: baseFontSize,
            },
            formatter: (value, context) => {
              return `${context.chart.data.labels[context.dataIndex]}`;
            },
          },
        },
      };
      setChartOptions(newOptions);
    };
    getETHPrice();
    getBalances();
    updateChartOptions();
    window.addEventListener("resize", updateChartOptions);

    return () => window.removeEventListener("resize", updateChartOptions);
    // eslint-disable-next-line
  }, []);

  const pieData = Object.values(balances).map(
    (balance) => balance / 13000000000
  );
  const totalPercentage = pieData.reduce(
    (sum, percentage) => sum + percentage,
    0
  );
  const otherPercentage = 1 - totalPercentage;
  const pieLabels = Object.keys(balances)
    .map(
      (name) =>
        `${name}\n(${((balances[name] / 13000000000) * 100).toFixed(2)}%)`
    )
    .concat(`ETH HOLDERS\n(${(otherPercentage * 100).toFixed(2)}%)`);
  const pieColors = [
    "#495670",
    "#4A6D7C",
    "#6E6A8E",
    "#787489",
    "#827e84",
    "#9E9E9E",
  ];

  const pieChartData = {
    labels: pieLabels,
    datasets: [
      {
        data: [...pieData, otherPercentage],
        backgroundColor: pieColors,
      },
    ],
  };

  return (
    <div className="token">
      <h1>$FEDS</h1>
      <div className="flex spacebetween">
        <div className="width infoBox centered">
          <h5>
            SUPPLY:
            <br /> 13 BILLION
          </h5>
          <br />
          <h5>
            PRICE:
            <br />$
            {(
              ((14227957874.5 / balances["ETH LP"]) * ethPrice) /
              (balances["ETH LP"] / 13000000000) /
              13000
            ).toFixed(2)}
            {" PER MILLION"}
          </h5>
          <br />
          <h5>
            MARKET CAP:
            <br />$
            {(
              ((14227957874.5 / balances["ETH LP"]) * ethPrice) /
              (balances["ETH LP"] / 13000000000)
            ).toFixed(2)}
          </h5>
        </div>
        <div className="width">
          <div className="pieContainer">
            <Pie
              data={pieChartData}
              options={chartOptions}
              plugins={[ChartDataLabels]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Token;
