import "./header.css";
import text from "./text.png";

const Header = () => {
  return (
    <div>
      <div className="header">
        <div className="title">
          <img className="title-image" src={text} alt="DEEP STATE" />
        </div>
        <div className="navigation"></div>
        <a
          href="https://app.uniswap.org/swap?&inputCurrency=ETH&outputCurrency=0xA1b91A8119B782c1d938389F84dbec70d25C8b25"
          target="_blank"
          rel="noreferrer"
        >
          <div className="buy button">BUY ON ETH</div>
        </a>
        <a
          href="https://app.uniswap.org/swap?inputCurrency=ETH&outputCurrency=0xb7be23456EE194B8cF66004a19dabb725bFF45f2&chain=base"
          target="_blank"
          rel="noreferrer"
        >
          <div className="buy button">BUY ON BASE</div>
        </a>
      </div>
      <div className="headerSpacer">
        <br />
      </div>
    </div>
  );
};

export default Header;
